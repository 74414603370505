// Import third-party modules
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';

// Import site tools and components
import InputGroupInlineLabel from './components/inputGroupInlineLabel';

//------------------------------------------------------------//

// Run this JS when all content on page has loaded
document.addEventListener('DOMContentLoaded', () => {
	console.log('loaded');

	InputGroupInlineLabel.init();
});

// For better performing resize functions
window.addEventListener(
  'resize',
  debounce(() => {
    console.log('resize');
  }, 150)
);

// For better performing scroll functions
window.addEventListener(
  'scroll',
  throttle(() => {
    console.log('scroll');
  }, 150)
);