const InputGroupInlineLabel = (() => {
  const fields = document.querySelectorAll('[data-inline-label]');

  return {
    init: () => {
      if (fields.length > 0) { 
        [...fields].forEach(field => {
          const input = field.querySelector('input, textarea, select');
          const label = field.querySelector('label');

          if (input.value !== "") {
            field.classList.add('is-dirty');
          }
          input.addEventListener('focus', () => {
            field.classList.add('is-focused');
          });
          input.addEventListener('blur', () => {
            if (input.value !== "") {
              field.classList.add('is-dirty');
              field.classList.remove('is-focused');
            } else {
              field.classList.remove('is-dirty');
              field.classList.remove('is-focused');
            }
          });
        })
      }
    }
  }
})();

export default InputGroupInlineLabel;